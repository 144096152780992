import React from "react";
import { useNavigate } from "react-router-dom";
import Email from "../assest/Email.jpg";  

const ScreenshotPage = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 p-6">
      <img
        src={Email}
        alt="Sent Successfully"
        className="w-48 h-48 mb-4"
      />
      <h1 className="text-3xl font-bold text-center mb-4">Sent Successfully</h1>
      <p className="text-center text-gray-700 mb-6">
        The policy clause has been sent to your registered email address. Please check your inbox.
      </p>
      <div className="flex gap-4">
        <button
          onClick={() => navigate("/")} // Navigate back to home
          className="px-4 py-2 bg-[#B03982] text-white rounded-lg"
        >
          Ask Another Question
        </button>
        <button
          onClick={() => navigate("/feedback")} // Navigate to feedback
          className="px-4 py-2 bg-[#B03982] text-white rounded-lg"
        >
          Send Feedback
        </button>
        <button
          onClick={() => navigate(-1)} // Navigate back to references
          className="px-4 py-2 bg-[#B03982] text-white rounded-lg"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ScreenshotPage;
