import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ChatHistory = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { session } = location.state || {};
  console.log("session:", session);

  if (!session) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <p className="text-gray-500">No session data found.</p>
      </div>
    );
  }

  const conversation = Array.isArray(session.conversation) ? session.conversation : [];
  console.log("conversation:", conversation)

  return (
    <div className="flex flex-col items-center min-h-screen bg-gray-50 p-6">
      <h2 className="text-2xl font-bold text-gray-700 text-center mb-6">
        Session History
      </h2>
      <div className="w-full max-w-4xl bg-white shadow-lg rounded-lg p-6">
        <h2 className="text-2xl font-bold text-gray-700 text-center mb-6">
          {session.name}
        </h2>
        <p className="text-gray-700 mb-4">
          <strong>Date:</strong> {session.date}
        </p>
        <div className="text-gray-700">
        {conversation.map((chat, index) => {
          if (chat.sender === "user") {
            return (
              <div key={index} className="mb-6">
                <p className="font-bold mb-1">Q: {chat.message}</p>
                {conversation[index + 1]?.sender === "ai" && (
                  <p className="pl-4">{conversation[index + 1].message}</p>
                )}
              </div>
            );
          }
          return null; 
        })}
      </div>
        <div className="flex justify-center space-x-4">
          <button
            className="px-4 py-2 bg-transparent border-2 border-[#B03982] text-[#B03982] rounded-3xl w-[11rem]"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
          <button
            className="px-4 py-2 bg-[#B03982] text-white rounded-3xl w-[11rem]"
            onClick={() => navigate("/")}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatHistory;
