import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const SessionHistory = () => {
  const [selectedSession, setSelectedSession] = useState(null);
  const [sessions, setSessions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const storedSessions = JSON.parse(sessionStorage.getItem("sessions")) || [];
    setSessions(storedSessions);
  }, []);

  const handleShowSession = () => {
    if (selectedSession) {
      const session = sessions.find((s) => s.name === selectedSession);
      navigate(`/session/${selectedSession}`, { state: { session } });
    } else {
      alert("Please select a session.");
    }
  };

  const filteredSessions = sessions.filter((session) =>
    session.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="flex flex-col items-center min-h-screen bg-gray-50 p-6">
      <div className="w-full max-w-4xl bg-white shadow-lg rounded-lg p-6">
        <h2 className="text-2xl font-bold text-gray-700 text-center mb-6">
          Session History
        </h2>
        <div className="relative w-full mb-6">
          <input
            type="text"
            placeholder="Search Session History"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full border border-gray-300 rounded-full py-2 px-4"
          />
        </div>
        <div className="divide-y divide-gray-200">
          {filteredSessions.length > 0 ? (
            filteredSessions.map((session, index) => (
              <div
                key={index}
                className="flex items-center justify-between py-3"
              >
                <label className="flex items-center space-x-3">
                  <input
                    type="radio"
                    name="session"
                    value={session.name}
                    checked={selectedSession === session.name}
                    onChange={() => setSelectedSession(session.name)}
                    className="form-radio h-4 w-4 text-purple-500"
                  />
                  <span className="text-gray-700">{session.name}</span>
                </label>
                <span className="text-gray-500">{session.date}</span>
              </div>
            ))
          ) : (
            <p className="text-gray-500 text-center">No sessions available.</p>
          )}
        </div>
        <div className="flex justify-center space-x-4 mt-6">
          <button
            className="px-4 py-2 bg-transparent border-2 border-[#B03982] text-[#B03982] rounded-3xl w-[11rem]"
            onClick={handleShowSession}
          >
            Show Session
          </button>
          <button
            className="px-4 py-2 bg-[#B03982] text-white rounded-3xl w-[11rem]"
            onClick={() => navigate("/")}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default SessionHistory;
