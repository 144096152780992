import React, { useState, useEffect } from 'react';
import character from "../assest/character.png";

const DetailedViewDrawer = ({ conversation }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showCharacter, setShowCharacter] = useState(false);

  useEffect(() => {
    let timer;
    if (isOpen) {
      timer = setTimeout(() => {
        setShowCharacter(true);
      }, 300); // Delay for character appearance
    } else {
      setShowCharacter(false);
    }
    return () => clearTimeout(timer);
  }, [isOpen]);

  return (
    <div className="w-full p-4">
      {/* Button to open drawer */}
      <button
        onClick={() => setIsOpen(true)}
        className="w-40 rounded-xl bg-gray-800 text-white p-2"
      >
        <h2 className="text-lg text-white">Detailed View</h2>
      </button>

      {/* Overlay */}
      {isOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={() => setIsOpen(false)}
        />
      )}

      {/* Character Image Container */}
      {isOpen && (
        <div 
          className={`fixed bottom-5 right-[80%] z-50 transform transition-all duration-1000 ease-in-out ${
            showCharacter 
              ? 'translate-x-0 opacity-100' 
              : 'translate-x-[30rem] opacity-0'
          }`}
          style={{
            transitionDelay: showCharacter ? '200ms' : '0ms'
          }}
        >
          <div className="w-60 h-96">
            <img
              src={character}
              alt="Character"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      )}

      {/* Drawer */}
      <div
        className={`fixed top-0 right-0 h-screen w-4/5 rounded-l-lg bg-gray-100 shadow-xl z-40 transform transition-transform duration-1000 ease-in-out ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        {/* Drawer Header */}
        <div className="flex items-center justify-between p-6 border-b">
          <h2 className="text-xl font-bold text-gray-800">Detailed View</h2>
          <button
            onClick={() => setIsOpen(false)}
            className="text-gray-500 hover:text-gray-700"
          >
            <span className="text-xl">&times;</span>
          </button>
        </div>

        {/* Drawer Content */}
        <div className="p-6 h-[calc(100vh-88px)] overflow-y-auto custom-scrollbar">
          {conversation.length > 0 ? (
            <div className="space-y-4">
              {conversation.map((msg) => (
                <div key={msg.id} className="bg-pink-50 rounded-lg p-4">
                  <p className="text-lg font-semibold text-gray-800 inline">
                    {msg.sender === "user" ? "Q: " : ""}
                  </p>
                  <p className="text-gray-700 inline font-bold">
                    {msg.sender === "user" ? msg.message : ""}
                  </p>
                  <p className="text-gray-700 mt-2">
                    {msg.sender !== "user" ? msg.message : ""}
                  </p>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-gray-500 text-center">
              Ask a question to see the detailed response here.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailedViewDrawer;