import React, { useState } from "react";
import { Lock, Eye, EyeOff } from "lucide-react";
import { useNavigate } from "react-router-dom";
import character from "../assest/cambine.jpg";

const ChangePassword = () => {
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [showPasswords, setShowPasswords] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate()

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const togglePasswordVisibility = (field) => {
    setShowPasswords({
      ...showPasswords,
      [field]: !showPasswords[field],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.newPassword !== formData.confirmPassword) {
      setError("Passwords do not match!");
      return;
    }

    setError(null);
    setLoading(true);

    const email = JSON.parse(localStorage.getItem("user"));
    const requestBody = {
      email: email, 
      password: formData.newPassword,
      confirmPassword: formData.confirmPassword,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_PORTAL_APP_BASE_URL}/auth/resetPasswordByEmail`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Something went wrong");
      }

      if (response.status === 200 || response.status === 201) {
        setShowModal(true); 
      } else {
        console.error("Failed to change Password:", response.statusText);
      }

      setFormData({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-white p-8 mt-32 relative z-[-1]">
      <div className="flex">
        <div className="hidden md:flex w-1/2 items-center justify-center bg-white">
          <img
            src={character}
            alt="Login"
            className="w-[70%] h-auto object-contain"
          />
        </div>
        <div className="w-full md:w-1/2 flex flex-col items-center relative z-0">
          <div className="w-full max-w-md space-y-8 relative z-0">
            <div className="text-center">
              <h1 className="text-2xl font-medium text-gray-800 mb-6">
                Change Password
              </h1>
              <div className="w-16 h-16 mx-auto mb-8 rounded-full border-2 border-[#B03982] flex items-center justify-center bg-white">
                <div className="w-12 h-12 rounded-full flex items-center justify-center">
                  <Lock className="w-8 h-8 text-[#B03982]" />
                </div>
              </div>
            </div>
            {error && (
              <div className="text-red-500 text-center mb-4">{error}</div>
            )}
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="relative">
                {/* <input
                  type={showPasswords.currentPassword ? "text" : "password"}
                  name="currentPassword"
                  value={formData.currentPassword}
                  onChange={handleChange}
                  placeholder="Current Password"
                  className="w-full px-4 py-3 rounded-md border border-[#B03982] focus:outline-none focus:border-[#B03982]"
                /> */}
                {/* <button
                  type="button"
                  onClick={() => togglePasswordVisibility("currentPassword")}
                  className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-[#B03982]"
                >
                  {showPasswords.currentPassword ? (
                    <EyeOff className="w-5 h-5" />
                  ) : (
                    <Eye className="w-5 h-5" />
                  )}
                </button> */}
              </div>
              <div className="relative">
                <input
                  type={showPasswords.newPassword ? "text" : "password"}
                  name="newPassword"
                  value={formData.newPassword}
                  onChange={handleChange}
                  placeholder="New Password"
                  className="w-full px-4 py-3 rounded-md border border-[#B03982] focus:outline-none focus:border-[#B03982]"
                />
                <button
                  type="button"
                  onClick={() => togglePasswordVisibility("newPassword")}
                  className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-[#B03982]"
                >
                  {showPasswords.newPassword ? (
                    <EyeOff className="w-5 h-5" />
                  ) : (
                    <Eye className="w-5 h-5" />
                  )}
                </button>
              </div>
              <div className="relative">
                <input
                  type={showPasswords.confirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  placeholder="Confirm Password"
                  className="w-full px-4 py-3 rounded-md border border-[#B03982] focus:outline-none focus:border-[#B03982]"
                />
                <button
                  type="button"
                  onClick={() => togglePasswordVisibility("confirmPassword")}
                  className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500 hover:text-[#B03982]"
                >
                  {showPasswords.confirmPassword ? (
                    <EyeOff className="w-5 h-5" />
                  ) : (
                    <Eye className="w-5 h-5" />
                  )}
                </button>
              </div>
              <div className="flex gap-4 justify-center pt-4">
                <button
                  type="button"
                  className="px-8 py-2.5 rounded-md bg-[#B03982] text-white hover:bg-[#B03982] transition-colors"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-8 py-2.5 rounded-md bg-[#B03982] text-white hover:bg-[#B03982] transition-colors"
                  disabled={loading}
                >
                  {loading ? "Saving..." : "Save"}
                </button>
              </div>
            </form>
            {/* Modal */}
              {showModal && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-60">
                  <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full">
                    <div className="text-center">
                      <div className="mb-6">
                        <div className="w-20 h-20 mx-auto bg-blue-500 rounded-full flex items-center justify-center">
                          <span className="text-white text-5xl">✔</span>
                        </div>
                      </div>
                      <h2 className="text-3xl font-bold mb-4">Thank You!</h2>
                      <p className="text-lg mb-6">
                      Your Password has been successfully changes. You can now use your new credentials
                      to log in. 
                      </p>
                      <button
                        className="px-6 py-3 bg-[#B03982] text-white rounded-lg text-lg hover:bg-[#9A316E] transition"
                        onClick={() => {
                          setShowModal(false);
                          navigate("/");
                        }}
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
