import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Define the API endpoint
const API_URL = `${process.env.REACT_APP_PORTAL_APP_BASE_URL}/auth/login`;

// Async thunk for login
export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await axios.post(API_URL, { email, password });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Slice
const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    token: null,
    isLoading: false,
    isAuthenticated: false,
    error: null,
  },
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.isAuthenticated = false;
      localStorage.removeItem('user');
      localStorage.removeItem('token');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        console.log("Login Successful - Payload:", action.payload); // Debug response payload
        const { data } = action.payload; 
        state.isLoading = false;
        state.user = data.email || null;
        state.token = data.token || null;
        state.isAuthenticated = true;
      
        // Save user and token in localStorage
        localStorage.setItem("user", JSON.stringify(data.email || {})); 
        localStorage.setItem("token", data.token || "");
        localStorage.setItem("profileData", JSON.stringify(data));

        console.log("User saved to localStorage:", data.email);
        console.log("Token saved to localStorage:", data.token); 
        console.log("profileData saved to localStorage:", data);
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || 'Failed to login.';
      });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
