import React, { useEffect, useState } from "react";
import character from "../assest/cambine.jpg";

const ProfileSettings = () => {
  const [profileData, setProfileData] = useState({
    employeeName: "",
    employeeId: "",
    emailId: "",
    company: "",
    department: "",
    initial: "",
  });

  useEffect(() => {
    const storedData = localStorage.getItem("profileData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setProfileData({
        employeeName: parsedData.name || "N/A",
        employeeId: parsedData.employeeId || "N/A",
        emailId: parsedData.email || "N/A",
        company: parsedData.company?.companyName || "N/A",
        department: parsedData.department?.departmentName || "N/A",
        initial: parsedData.name ? parsedData.name.charAt(0) : "N",
      });
    }
  }, []);

  return (
    <div className="min-h-screen bg-white p-8 mt-32">
      <div className="flex">
        <div className="hidden md:flex w-1/2 items-center justify-center bg-white">
          <img
            src={character}
            alt="Profile Character"
            className="w-[70%] h-auto object-contain"
          />
        </div>
        <div className="w-full md:w-1/2 flex flex-col items-center">
          <div className="flex items-center gap-4 flex-col">
            <h1 className="text-xl font-medium text-gray-800">
              Profile Settings
            </h1>
            <div className="w-16 h-16 rounded-full bg-gray-800 border-2 border-purple-500 flex items-center justify-center">
              <span className="text-2xl font-medium text-white">
                {profileData.initial}
              </span>
            </div>
            <div className="flex items-center gap-4 flex-col">
            <p className="text-lg text-purple-600">
                {profileData.employeeName}
              </p>
              <p className="block text-gray-600 mb-1">{profileData.company}</p>
              <div className="h-px bg-gray-200 mt-2"></div>
            </div>
          </div>
          <div className="pt-8 w-full flex flex-col">
            <div className="flex items-center gap-4 flex-col">
              <div className="w-[50%]">
                <div className="flex justify-between gap-5">
                  <label className="block text-gray-600 mb-1">
                    Employee ID
                  </label>
                  <p className="text-lg text-gray-900">
                    {profileData.employeeId}
                  </p>
                </div>
                <div className="h-px bg-gray-200 mt-2"></div>
              </div>

              <div className="w-[50%]">
                <div className="flex justify-between gap-5">
                  <label className="block text-gray-600 mb-1">Email ID</label>
                  <p className="text-lg text-gray-900">{profileData.emailId}</p>
                </div>
                <div className="h-px bg-gray-200 mt-2"></div>
              </div>

              <div className="w-[50%]">
                <div className="flex justify-between gap-5">
                  <label className="block text-gray-600 mb-1">Department</label>
                  <p className="text-lg text-gray-900">
                    {profileData.department}
                  </p>
                </div>
                <div className="h-px bg-gray-200 mt-2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSettings;
