import React, { useState } from 'react';

const SignOutButton = ({ handleSignOut }) => {
  const [showModal, setShowModal] = useState(false);

  const onConfirm = () => {
    handleSignOut();
    setShowModal(false);
  };

  return (
    <>
      <button
        onClick={() => setShowModal(true)}
        className="nav_link flex items-center px-4 py-2 text-black hover:bg-red-200 rounded-full w-full"
      >
        <i className="bx bx-log-out nav_icon text-xl mr-3"></i>
        <span className="nav_name">SignOut</span>
      </button>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[9999]">
          <div className="bg-white rounded-lg p-6 w-[40%] h-[70%] relative z-[9999]">
            <h2 className="text-xl font-semibold mb-4 text-center mt-20 relative z-[9999]">Confirm Sign Out!</h2>
            <p className="text-center mb-6 mt-10">Are you sure you want to sign out?</p>
            
            <div className="flex justify-center space-x-4 absolute bottom-10 left-[20%]">
              <button
                onClick={onConfirm}
                className="px-4 py-2 bg-transparent border-2 border-[#B03982] text-[#B03982] rounded-3xl w-[11rem]"
              >
                Yes
              </button>
              <button
                onClick={() => setShowModal(false)}
                className="px-4 py-2 bg-[#B03982] text-white rounded-3xl w-[11rem]"
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SignOutButton;