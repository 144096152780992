import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import character from "../assest/cambine.jpg"; // Ensure this path is correct

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email;
  console.log("email:", email)

  const handleResetPassword = () => {
    // Check if passwords match
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    axios
      .post(
        `${process.env.REACT_APP_PORTAL_APP_BASE_URL}/auth/resetPasswordByEmail`,
        {
          email,
          password,
          confirmPassword,
        }
      )
      .then((response) => {
        setMessage("Password reset successful.");
        setTimeout(() => {
          navigate("/login"); // Redirect to login page after successful reset
        }, 3000);
      })
      .catch((err) => {
        setError(err?.response?.data?.message || "Error resetting password.");
      });
  };

  // Automatically hide the error message after 3 seconds
  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(null); // Clear error after 3 seconds
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="rounded-3xl w-[80%] overflow-hidden shadow-lg flex rounded overflow-hidden shadow-lg">
        {/* Left Side - Character Image */}
        <div className="hidden md:flex w-1/2 items-center justify-center bg-white">
          <img
            src={character}
            alt="Reset Password"
            className="w-[70%] h-auto object-contain"
          />
        </div>

        {/* Right Side - Reset Password Form */}
        <div className="w-full md:w-1/2 flex items-center justify-center p-8 bg-white">
          {/* Error Notification Bar */}
          {error && (
            <div
              className="fixed top-4 left-1/2 transform -translate-x-1/2 w-96 p-4 bg-red-600 text-white rounded-lg shadow-md flex items-center justify-between transition-all duration-500"
              style={{
                opacity: error ? 1 : 0,
                top: error ? "16px" : "-100px",
              }}
            >
              <span>{error}</span>
              <button
                className="ml-4 bg-red-800 rounded-full w-10 p-1 text-lg"
                onClick={() => setError(null)}
              >
                ×
              </button>
            </div>
          )}
          <div className="w-full max-w-md">
            {message && (
              <div className="text-green-600 text-center mb-4 text-2xl font-bold">
                {message}
              </div>
            )}
            <h2 className="text-3xl font-bold text-center text-gray-700 mb-6">
              Reset Your Password
            </h2>
            {/* <div className="mb-6">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-600"
              >
                Email Address
              </label>
              <input
                id="email"
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-3 mt-2 border-2 border-[#B03982] rounded-lg focus:outline-none focus:ring-0 focus:ring-[#B03982]"
              />
            </div> */}
            <div className="mb-6">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-600"
              >
                New Password
              </label>
              <input
                id="password"
                type="password"
                placeholder="Enter your new password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full p-3 mt-2 border-2 border-[#B03982] rounded-lg focus:outline-none focus:ring-0 focus:ring-[#B03982]"
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="confirmPassword"
                className="block text-sm font-medium text-gray-600"
              >
                Confirm New Password
              </label>
              <input
                id="confirmPassword"
                type="password"
                placeholder="Confirm your new password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="w-full p-3 mt-2 border-2 border-[#B03982] rounded-lg focus:outline-none focus:ring-0 focus:ring-[#B03982]"
              />
            </div>
            <button
              onClick={handleResetPassword}
              className="w-full py-3 bg-gradient-to-b from-[#B03982] to-[#733C86] text-white font-semibold rounded-full hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500"
            >
              Reset Password
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
