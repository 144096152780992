import React from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const References = ({ isOpen, onClose, references }) => {
  const navigate = useNavigate();
  console.log('references:',references)

  const processReferences = (references) => {
    const [tablePart, detailsPart] = references.split("\n\n**Details:**\n\n");
    const rows = tablePart.split("\n").slice(2);
    const structuredReferences = rows.map((row) => {
      const columns = row.split("|").map((col) => col.trim()).filter(Boolean);
      return {
        documentName: columns[0],
        sectionName: columns[1],
        sectionNumber: columns[2],
      };
    });

    return { structuredReferences, detailsPart };
  };

  const { structuredReferences, detailsPart } = processReferences(references);

  const handleEmailClick = async () => {
    try {
      const token = localStorage.getItem("token");
      const email = localStorage.getItem("user");
      if (!email) {
        alert("User email not found in local storage!");
        return;
      }
  
      const emailContent = `
      <!DOCTYPE html>
      <html>
        <head>
          <style>
            body { font-family: Arial, sans-serif; background-color: #f4ecfa; margin: 0; padding: 20px; }
            .container { max-width: 600px; margin: auto; background: white; padding: 20px; border-radius: 10px; box-shadow: 0px 0px 10px rgba(0,0,0,0.1); }
            h1 { text-align: center; color: #282828; }
            table { width: 100%; border-collapse: collapse; margin-top: 20px; }
            th, td { border: 1px solid #ddd; padding: 10px; text-align: left; }
            th { background-color: #733C86; color: white; }
            tr:nth-child(even) { background-color: #f9f9f9; }
            .details { margin-top: 20px; }
            .footer { text-align: center; margin-top: 20px; font-size: 14px; color: #6e6e6e; }
          </style>
        </head>
        <body>
          <div class="container">
            <h1>References</h1>
            ${
              structuredReferences.length > 0
                ? `<table>
                    <thead>
                      <tr>
                        <th>Document Name</th>
                        <th>Section Name</th>
                        <th>Section Number</th>
                      </tr>
                    </thead>
                    <tbody>
                      ${structuredReferences
                        .map(
                          (ref) => `
                        <tr>
                          <td>${ref?.documentName}</td>
                          <td>${ref?.sectionName}</td>
                          <td>${ref?.sectionNumber}</td>
                        </tr>
                      `
                        )
                        .join("")}
                    </tbody>
                  </table>`
                : "<p>No references available.</p>"
            }
            ${
              detailsPart
                ? `<div class="details">
                    <h3>Details:</h3>
                    <p>${detailsPart.replace(/\n/g, "<br>")}</p>
                  </div>`
                : ""
            }
            <div class="footer">This information was generated by AI, use with caution.<br />NerdyBuddy</div>
          </div>
        </body>
      </html>`;
  
      const data = { email, content: emailContent };
  
      const response = await axios.post(
        `${process.env.REACT_APP_PORTAL_APP_BASE_URL}/client/sendRefrenceEmail`,
        data,
        { headers: { token, "Content-Type": "application/json" } }
      );
  
      if (response.status === 200 || response.status === 201) {
        navigate("/references-email");
      } else {
        console.error("Failed to send the email.", response.statusText);
      }
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };  

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white w-11/12 max-w-4xl p-6 rounded-lg shadow-lg relative">
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
          onClick={onClose}
        >
          ✖
        </button>
        <h1 className="text-2xl font-bold text-center mb-4">References</h1>
        {structuredReferences.length > 0 ? (
          <>
            <table className="table-auto w-full border-collapse border border-gray-300">
              <thead>
                <tr className="bg-gray-200">
                  <th className="border border-gray-300 px-4 py-2 text-left">Document Name</th>
                  <th className="border border-gray-300 px-4 py-2 text-left">Section Name</th>
                  <th className="border border-gray-300 px-4 py-2 text-left">Section Number</th>
                </tr>
              </thead>
              <tbody>
                {structuredReferences.map((ref, index) => (
                  <tr key={index} className="hover:bg-gray-100">
                    <td className="border border-gray-300 px-4 py-2">{ref?.documentName}</td>
                    <td className="border border-gray-300 px-4 py-2">{ref?.sectionName}</td>
                    <td className="border border-gray-300 px-4 py-2">{ref?.sectionNumber}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {detailsPart && (
              <div className="mt-4">
                <p className="font-bold">Details:</p>
                <ReactMarkdown
                  className="text-gray-600"
                  remarkPlugins={[remarkGfm]}
                >
                  {detailsPart}
                </ReactMarkdown>
              </div>
            )}
          </>
        ) : (
          <p className="text-gray-500 text-center">No references available.</p>
        )}
        <div className="flex justify-center mt-6 gap-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-[#B03982] text-white rounded-lg"
          >
            Ask Another Question
          </button>
          <button
            onClick={() => navigate("/feedback")}
            className="px-4 py-2 bg-[#B03982] text-white rounded-lg"
          >
            Share Feedback
          </button>
          <button
            onClick={handleEmailClick}
            className="px-4 py-2 bg-[#B03982] text-white rounded-lg"
          >
            Email this to me
          </button>
        </div>
      </div>
    </div>
  );
};

export default References;
