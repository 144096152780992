import React, { useState, useEffect } from "react";
import character from "../assest/character.png";
import Modal from "./Modal";
import Refine from "./refine";
import References from "./References";
import Doc from "./documentselector";
import loadingGif from "../assest/Loading.gif";
import DetailedViewDrawer from "../components/detailedView.jsx";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const Home = () => {
  const [question, setQuestion] = useState("");
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [, setResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const [conversation, setConversation] = useState([]);
  const [references, setReferences] = useState("");
  const [suggestedQuestions, setSuggestedQuestions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [documentDetails, setDocumentDetails] = useState([]);
  const [responseStream, setResponseStream] = useState("");
  const [helperButton, setHelperButton] = useState(false);
  const [helperLoader, setHelperLoader] = useState(false);
  const [refineCount,] = useState(0);
  const [documentCount, setDocumentCount] = useState(0);
  const [sessionNumber, setSessionNumber] = useState(1);
  const [, setSessions] = useState([]);
  const [, setLatestSession] = useState(null); 
  // const [isDetailedViewOpen, setIsDetailedViewOpen] = useState(false);

  useEffect(() => {
    const savedSessions = JSON.parse(sessionStorage.getItem("sessions")) || [];
    const savedSessionNumber = parseInt(sessionStorage.getItem("sessionNumber"), 10) || 1;

    setSessions(savedSessions);
    if (savedSessions.length > 0) {
      const lastSession = savedSessions[savedSessions.length - 1] || {};
      setConversation(lastSession.conversation || []);
      setLatestSession(savedSessions[savedSessions.length - 1].name);
    }
    setSessionNumber(savedSessionNumber);
  }, []);

  useEffect(() => {
    const savedSessions = JSON.parse(sessionStorage.getItem("sessions")) || [];
    const updatedSessions = savedSessions.map((session, index) =>
      index === savedSessions.length - 1 ? { ...session, conversation } : session
    );

    if (updatedSessions.length > 0) {
      sessionStorage.setItem("sessions", JSON.stringify(updatedSessions));
    }
  }, [conversation]);

  useEffect(() => {
    sessionStorage.setItem("sessionNumber", sessionNumber);
  }, [sessionNumber]);

  const handleStream = async () => {
    if (!question.trim()) return;
  
    setCurrentQuestion(question);
    setLoading(true);
    setResponse("");
    setResponseStream("");
    setHelperButton(false);
  
    try {
      const responseStream = await fetch(
        `${process.env.REACT_APP_BASEURL}/stream_response`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            query: question,
            chat_history: conversation.map((msg) => msg.message),
            language: "en",
          }),
        }
      );
  
      if (!responseStream.ok) {
        throw new Error(
          `Server responded with status ${responseStream.status}`
        );
      }
  
      const reader = responseStream.body.getReader();
      const decoder = new TextDecoder();
      let done = false;
      let accumulatedResponse = "";
  
      while (!done) {
        const { value, done: streamDone } = await reader.read();
        done = streamDone;
      
        if (value) {
          const chunk = decoder.decode(value, { stream: true });
      
          accumulatedResponse += chunk;
          setLoading(false);
          setQuestion("");

          setResponseStream((prev) => (prev || "") + chunk);
        }
      }
      

      const formattedResponse = accumulatedResponse
        .replace(/answer:\s*/gi, "")
        .trim();
  
      setResponseStream(formattedResponse);
  
      setConversation((prev) => [
        ...prev,
        { id: `user-${Date.now()}`, sender: "user", message: question },
        {
          id: `ai-${Date.now()}`,
          sender: "ai",
          message: formattedResponse,
        },
      ]);
    } catch (error) {
      console.error("Error fetching response:", error);
    } finally {
      setLoading(false);
    }
  };
  

  const handleJSON = async () => {
    if (!question.trim()) return;
    setCurrentQuestion(question);
    setHelperLoader(true);
    setResponse("");
    setHelperButton(true);

    try {
      const apiResponse = await fetch(
        `${process.env.REACT_APP_BASEURL}/query_response`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            query: question,
            chat_history: conversation.map((msg) => msg.message),
            language: "en",
          }),
        }
      );

      if (!apiResponse.ok) {
        throw new Error(`Server responded with status ${apiResponse.status}`);
      }

      const data = await apiResponse.json();

      setSuggestedQuestions(data.suggested_questions || []);
      setReferences(data.references || "No references found.");
      setDocumentDetails(data.document_details || []);

      if (data.document_details) {
        const parsedDocuments = data.document_details.split("\n").map((line) => {
          const data = line.split(",").map((item) => item.trim());
          const docObj = {};
  
          data.forEach((item) => {
            const [key, value] = item.split(":").map((part) => part.trim());
            if (key && value) {
              docObj[key] = value;
            }
          });
  
          return docObj;
        });

        setDocumentCount(parsedDocuments.length);
      }

      setCurrentQuestion(question);
      setQuestion("");
      setHelperLoader(false);
    } catch (error) {
      console.error("Error fetching response:", error);
    } finally {
      setLoading(false);
      setHelperLoader(false);
    }
  };

  const handleSend = async () => {
    if (!question.trim()) return;
    setLoading(true);

    try {
      // await Promise.all([handleStream(), handleJSON()]);
      await handleStream();
      await handleJSON();
    } catch (error) {
      console.error("Error executing APIs:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleNavigateToReferences = () => {
    setModalType("references");
    setIsModalOpen(true);
  };

  const handleRefineSuggestions = () => {
    setModalType("refine");
    setIsModalOpen(true);
  };

  const handleEditDoc = () => {
    setModalType("doc");
    setIsModalOpen(true);
  };

  const handleSelectOption = async (selectedOption) => {
    let displayedQuestion = selectedOption;
    if (typeof selectedOption === "object") {
      displayedQuestion = currentQuestion;
      selectedOption = currentQuestion + "? in " + selectedOption.file_name + "?";
    }
  
    setCurrentQuestion(displayedQuestion);
    setLoading(true);
    setIsModalOpen(false);
    setResponseStream(null);
    setResponse(null);
    setSuggestedQuestions([]);
    setHelperLoader(true);
    setHelperButton(true);
  
    try {
      const baseUrl = process.env.REACT_APP_BASEURL;
      const payload = {
        query: selectedOption,
        chat_history: conversation.map((msg) => msg.message),
        language: "en",
      };
  
      const streamResponse = await fetch(`${baseUrl}/stream_response`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(payload),
      });
  
      if (!streamResponse.ok) {
        throw new Error(`Stream API error: ${streamResponse.status}`);
      }
  
      const reader = streamResponse.body.getReader();
      const decoder = new TextDecoder();
      let done = false;
      let fullStreamData = "";
  
      while (!done) {
        const { value, done: streamDone } = await reader.read();
        done = streamDone;
  
        const chunk = decoder.decode(value, { stream: true });
        fullStreamData += chunk;
        setLoading(false);
  
        setResponseStream((prev) => (prev || "") + chunk);
      }
  
      const jsonResponse = await fetch(`${baseUrl}/query_response`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(payload),
      });
  
      const jsonData = await jsonResponse.json();
  
      const newEntry = [
        { id: `user-${Date.now()}`, sender: "user", message: displayedQuestion },
        {
          id: `ai-${Date.now()}`,
          sender: "ai",
          message: jsonData.answer || fullStreamData.trim(),
        },
      ];
  
      setConversation((prev) => [...prev, ...newEntry]);
  
      setResponse(jsonData.answer || fullStreamData.trim());
      setReferences(jsonData.references || "No references found.");
      setSuggestedQuestions(jsonData.suggested_questions || []);
      setDocumentDetails(jsonData.document_details || []);
  
      if (jsonData.document_details) {
        const parsedDocuments = jsonData.document_details.split("\n").map((line) => {
          const data = line.split(",").map((item) => item.trim());
          const docObj = {};
  
          data.forEach((item) => {
            const [key, value] = item.split(":").map((part) => part.trim());
            if (key && value) {
              docObj[key] = value;
            }
          });
  
          return docObj;
        });
  
        setDocumentCount(parsedDocuments.length);
      }
  
      setHelperLoader(false);
      setHelperButton(true);
  
      console.log("Stream response:", fullStreamData);
    } catch (error) {
      console.error("Error fetching response:", error);
    } finally {
      setLoading(false);
      setHelperLoader(false);
    }
  };

  // const handleNewSession = () => {
  //   const newSession = {
  //     id: `Session-${sessionNumber}`,
  //     name: `Session ${sessionNumber}`,
  //     date: new Date().toLocaleString(),
  //     conversation,
  //   };
  //   setLatestSession(newSession.name);
  //   const updatedSessions = [...sessions, newSession];
  //   setSessions(updatedSessions);
  //   setConversation([]);

  //   sessionStorage.setItem("sessions", JSON.stringify(updatedSessions));
  //   sessionStorage.setItem("conversation", JSON.stringify([]));
  //   sessionStorage.setItem("sessionNumber", sessionNumber + 1);

  //   setSessionNumber((prev) => prev + 1);
  // }; 

  return (
    <div className="min-h-full w-full flex h-[92vh]">
      <style>
        {`
          .custom-scrollbar::-webkit-scrollbar {
            width: 5px;
          }
          
          .custom-scrollbar::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 10px;
            display: none;
          }
          
          .custom-scrollbar::-webkit-scrollbar-thumb {
            background: #B03982;
            border-radius: 10px;
            display: none;
          }
          
          .custom-scrollbar.has-content::-webkit-scrollbar-track,
          .custom-scrollbar.has-content::-webkit-scrollbar-thumb {
            display: block;
          }
          
          .custom-scrollbar::-webkit-scrollbar-thumb:hover {
            background: #8c2d66;
          }

          .whitespace-pre-line {
            white-space: pre-line;
          }
        `}
      </style>
      <div className="w-full flex items-end justify-between">
        {/* First Div - Character Image */}
        <div></div>

        {/* Middle Div - Main Chat Area */}
        <div className="h-screen p-4 flex flex-col justify-between flex-1 max-w-[50%] ml-20">
          {/* Top Section - Chat Messages */}
          <div className="flex flex-col gap-4 mt-[5.9rem] h-[65vh] overflow-y-scroll custom-scrollbar">


            {/* Header with new session handler */}
            {/* <Header onStartNewSession={handleNewSession} /> */}
            {/* Display session history */}
            {/* <div className="session-list mt-4"> */}
            {/* {latestSession && <h2 className="text-lg font-bold">{latestSession}</h2>} */}
            {/* </div> */}
            {/* Greeting Card */}
            <div className="w-full p-4 bg-gray-100 rounded-lg shadow-md">
              <p className="text-lg font-semibold text-gray-800 mb-2">
              Hey, you are looking awesome today. I am an AI based, intelligent being, developed in the UK. I am mastering your company’s regulations so I can assist you and your other fellows. Would you like to ask me a question?
              </p>
            </div>

            {/* Question and Response Area */}
            <div className="space-y-4">
              {/* Question Card */}
              {currentQuestion && (
                <div className="w-full p-4 bg-gray-100 rounded-lg shadow-md">
                  <h3 className="text-lg font-semibold text-gray-800 mb-2 break-words whitespace-normal">
                    Q: {currentQuestion}
                  </h3>
                </div>
              )}

              {/* Response Card */}
              {/* <div className="w-full p-4 bg-gray-100 rounded-lg shadow-md"> */}
              {loading ? (
                <div className="flex items-center justify-center h-full w-full">
                  <img
                    src={loadingGif}
                    alt="Loading..."
                    className="h-40 w-60" // Adjust the size here
                  />
                </div>
              ) : responseStream ? (
                <div className="w-full p-4 bg-gray-100 rounded-lg shadow-md">
                  <ReactMarkdown
                    className="text-gray-700 break-words whitespace-normal"
                    children={responseStream}
                    remarkPlugins={[remarkGfm]}
                    unwrapDisallowed={false}
                  />
                </div>
              ) : null}

            </div>
            {/* </div> */}

            {/* Action Buttons */}
          </div>

          {/* Bottom Section - Input Area */}
          <div className="">
            <div>
            {helperButton && (
              <div className="flex justify-center gap-2 mb-5 mt-5">
              {references && (references !== "No references found.") && (
                    <button
                    onClick={handleNavigateToReferences}
                    disabled={helperLoader}
                    className={`px-4 py-2 border-2 border-[#B03982] rounded-3xl w-[11rem] ${
                      helperLoader
                      ? "bg-gray-300 text-gray-600 cursor-not-allowed"
                      : "bg-transparent text-[#B03982] hover:bg-[#B03982] hover:text-white"
                    }`}
                    >
                    Show References
                </button>
              )}

              {suggestedQuestions.length > 0 && (
                <button
                  onClick={handleRefineSuggestions}
                  disabled={helperLoader}
                  className={`px-4 py-2 rounded-3xl w-[11rem] ${
                    helperLoader
                      ? "bg-gray-300 text-gray-600 cursor-not-allowed"
                      : "bg-[#B03982] text-white hover:bg-[#8c2d66]"
                  }`}
                >
                  Refine it
                </button>
              )}

                {documentCount > 1 && suggestedQuestions.length > 0 && references && (references !== "No references found.") && (
                  <button
                    onClick={handleEditDoc}
                    disabled={helperLoader}

                    className={`px-4 py-2 rounded-3xl w-[11rem] ${
                      helperLoader
                        ? "bg-gray-300 text-gray-600 cursor-not-allowed"
                        : "bg-[#B03982] text-white hover:bg-[#8c2d66]"
                    }`}
                  >
                    Edit Document
                  </button>
                )}
              </div>
            )}

            </div>
            <div className="flex items-center border border-gray-300 rounded-lg focus-within:ring-2 focus-within:ring-[#B03982] overflow-hidden">
              <input
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && question.trim() && !loading) {
                    handleSend();
                  }
                }}
                placeholder="Ask Buddy..."
                className="flex-grow h-12 px-4 focus:outline-none rounded-none"
              />
              <button
                onClick={handleSend}
                disabled={!question.trim() || loading}
                className={`px-4 py-2 mr-2  rounded-lg text-white font-semibold ${
                  question.trim() && !loading
                    ? "bg-[#B03982] hover:bg-[#B03982]"
                    : "bg-gray-300 cursor-not-allowed"
                }`}
              >
                <i className="fa-sharp fa-solid fa-paper-plane"></i>
              </button>
            </div>
          </div>
        </div>

        {/* Third Div - Detailed View */}
        <div className="p-4 pl-20 gap-36 flex flex-col">
          <DetailedViewDrawer conversation={conversation} />
          <div className="w-[15rem] h-[26rem]">
            <img
              src={character}
              alt="Character"
              className="w-full h-full object-cover relative z-[-1]"
            />
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        type={modalType}
      >
        {modalType === "refine" && (
          <Refine
            suggestedQuestions={suggestedQuestions}
            onSelectOption={handleSelectOption}
            onCloseModal={() => setIsModalOpen(false)}
            refineCount={refineCount}
          />
        )}
        {modalType === "doc" && (
          <Doc
            documentDetails={documentDetails}
            onSelectOption={handleSelectOption}
            onCloseModal={() => setIsModalOpen(false)}
          />
        )}
        {modalType === "references" && (
          <References
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            references={references}
          />
        )}
      </Modal>
    </div>
  );
};

export default Home;
