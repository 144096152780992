import axios from "axios";
import React, { useState, useEffect } from "react";

const DocumentTypeForm = ({ documentDetails, onCloseModal, onSelectOption }) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [documents, setDocuments] = useState([]);

  const convertName = async (search) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PORTAL_APP_BASE_URL}/documents/getDocumentName`,
        { search },
        {
          headers: {
            "token": `${localStorage.getItem("token")}`,
          },
        }
      );
  
      if (response.status !== 200) {
        throw new Error("Failed to fetch document names");
      }
  
      return response.data.data;
    } catch (error) {
      console.error(error);
      return {};
    }
  };
  
  useEffect(() => {
    async function formatDoc() {
      if (documentDetails) {
        const parsedDocuments = documentDetails.split("\n").map((line) => {
          const data = line.split(",").map((item) => item.trim());
          const docObj = {};
  
          data.forEach((item) => {
            const [key, value] = item.split(":").map((part) => part.trim());
            if (key && value) {
              docObj[key] = value;
            }
          });
  
          return docObj;
        });
  
        const search = parsedDocuments.map((doc) => doc.file_name);
  
        const docNames = await convertName(search);
  
        const updatedDocuments = parsedDocuments.map((doc) => ({
          ...doc,
          file_name: docNames[doc.file_name] || doc.file_name,
        }));
  
        setDocuments(updatedDocuments);
      }
    }
  
    formatDoc();
  }, [documentDetails]);
  

  useEffect(() => {
    setSelectedRow(null);
  }, [documentDetails]);

  const handleShowResponse = () => {
    if (onCloseModal) {
      onSelectOption(documents[selectedRow]);
      onCloseModal();
    }
  };

  return (
    <div className="min-h-[85vh] bg-gray-50 flex justify-center items-center">
      <div className="w-full max-w-5xl p-6 rounded-lg">
        {/* Title */}
        <h2 className="text-2xl font-bold mb-8 text-center">
          Edit Document
        </h2>
        <p className="text-center text-sm text-gray-500 mb-8">
          We’ve found relevant information to answer your question across multiple documents.
          To proceed, please select one of the following documents below to generate your response.
        </p>

        {/* Table */}
        <div className="overflow-x-auto">
          <table className="w-full border-collapse border border-gray-200">
            <thead>
              <tr className="bg-pink-100 text-left">
                <th className="border border-gray-200 px-4 py-2">Document Name</th>
                <th className="border border-gray-200 px-4 py-2">Creation Date</th>
              </tr>
            </thead>
            <tbody>
              {documents.map((doc, index) => (
                <tr
                  key={index}
                  className={`${selectedRow === index ? "bg-pink-200" : "hover:bg-pink-50"
                    }`}
                >
                  <td className="border border-gray-200 px-4 py-2 flex items-center">
                    <input
                      type="radio"
                      name="document"
                      className="mr-2"
                      checked={selectedRow === index}
                      onChange={() => setSelectedRow(index)}
                    />
                    {doc.file_name}
                  </td>
                  <td className="border border-gray-200 px-4 py-2">{doc.creation_date}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Buttons */}
        <div className="flex justify-end p-4">
          <button
            type="button"
            className="px-4 py-2 bg-[#B03982] text-white rounded-3xl w-40"
            disabled={selectedRow === null}
            onClick={handleShowResponse}  // Close modal when clicked
          >
            Show Response
          </button>
        </div>
      </div>
    </div>
  );
};

export default DocumentTypeForm;
