import React, { useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

function EditRefine({ onSelectOption }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { suggestedQuestions = [], selectedIndexes: initialSelectedIndexes = {} } =
    location.state || {};
  const [selectedIndexes, setSelectedIndexes] = useState(initialSelectedIndexes);
  const [questions, setQuestions] = useState(suggestedQuestions);
  const [showModal, setShowModal] = useState(false);
  

  const handleOptionClick = (questionIndex, optionIndex) => {
    setSelectedIndexes((prev) => ({
      ...prev,
      [questionIndex]: optionIndex,
    }));
  };

  const handleOptionChange = (questionIndex, optionIndex, newValue) => {
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[questionIndex].options[optionIndex] = newValue;
      return updatedQuestions;
    });
  };

  const handleShowResponse = () => {
    const selected = Object.entries(selectedIndexes).map(([questionIndex, optionIndex]) => ({
      selectedOption: questions[questionIndex]?.options[optionIndex],
    }));

    if (selected.length > 0) {
      const { selectedOption } = selected[0];
      if (selectedOption) {
        onSelectOption(selectedOption);
      }
    }
  };

  const handleSubmitUpdate = async () => {
    try {
      await Promise.all(
        questions.map(async (question) => {
          const response = await axios.put(
            `${process.env.REACT_APP_BASEURL}/update-suggested-question`,
            {
              question: question.question,
              options: question.options,
            }
          );
          if (response.status === 200 || response.status === 201) {
            setShowModal(true); 
          } else {
            console.error("Failed to submit feedback:", response.statusText);
          }
          console.log("Updated question options:", response.data);
        })
      );
    } catch (error) {
      console.error("Error updating questions:", error);
      alert("Failed to update questions. Please try again.");
    }
  };

  return (
    <div className="p-[5.5rem]">
      <h2 className="text-2xl font-bold mb-8 text-center">Edit Refine</h2>
      {questions.length > 0 ? (
        <div>
          {questions.map((item, questionIndex) => (
            <div key={questionIndex} className="mb-6">
              <h3 className="text-lg font-semibold mb-4 text-center">
                {questionIndex + 1}. {item.question}
              </h3>
              <div className="grid grid-cols-2 gap-4">
                {item.options.map((option, optionIndex) => (
                  <div key={optionIndex} className="flex flex-col">
                    <input
                      type="text"
                      value={option}
                      onChange={(e) =>
                        handleOptionChange(questionIndex, optionIndex, e.target.value)
                      }
                      className="p-2 mt-2 border rounded-lg text-sm font-medium transition-colors duration-200"
                    />
                    <button
                      className={`p-2 mt-2 border rounded-lg text-sm font-medium transition-colors duration-200 ${
                        selectedIndexes[questionIndex] === optionIndex
                          ? "bg-[#f8d6e5] text-[#B03982] border-[#B03982]"
                          : "bg-transparent text-[#B03982] border-[#f8d6e5]"
                      } hover:bg-[#fce8f1]`}
                      onClick={() => handleOptionClick(questionIndex, optionIndex)}
                    >
                      Select
                    </button>
                  </div>
                ))}
              </div>
            </div>
          ))}
          <div className="flex justify-center gap-4 mt-8">
            <button
              className="mt-4 bg-[#B03982] text-white px-4 py-2 rounded-3xl"
              onClick={handleShowResponse}
            >
              Show Response
            </button>
            <button
              className="mt-4 bg-[#B03982] text-white px-4 py-2 rounded-3xl"
              onClick={() => navigate("/")}
            >
              Close
            </button>
            <button
              onClick={handleSubmitUpdate}
              className="mt-4 bg-[#B03982] text-white px-4 py-2 rounded-3xl"
            >
              Save Changes
            </button>
             {/* Modal */}
              {showModal && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
                  <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full">
                    <div className="text-center">
                      <div className="mb-6">
                        <div className="w-20 h-20 mx-auto bg-blue-500 rounded-full flex items-center justify-center">
                          <span className="text-white text-5xl">✔</span>
                        </div>
                      </div>
                      <h2 className="text-2xl font-bold mb-4">Refine Question Updated Succesfully!</h2>
                      <button
                        className="px-6 py-3 bg-[#B03982] text-white rounded-lg text-lg hover:bg-[#9A316E] transition"
                        onClick={() => {
                          setShowModal(false);
                          navigate("/edit-refine");
                        }}
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      ) : (
        <p className="text-center text-gray-500">No questions available to refine.</p>
      )}
    </div>
  );
}

export default EditRefine;