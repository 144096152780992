import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const FeedbackForm = () => {
  const [formData, setFormData] = useState(null);
  const [responses, setResponses] = useState({});
  const [rating, setRating] = useState(1); 
  const [loading, setLoading] = useState(true); 
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate()

  // Fetch form data from the API
  useEffect(() => {
    const fetchFormData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(
          `${process.env.REACT_APP_PORTAL_APP_BASE_URL}/feedback/form`,
          {
            method: "GET", 
            headers: { token: token,
              "Content-Type": "application/json" },
          }
        );
        const data = await response.json()
        setFormData(data.data[0]); 
      } catch (error) {
        console.error("Failed to fetch form data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchFormData();
  }, []);

  // Handle response change for each question
  const handleResponseChange = (questionId, answer) => {
    setResponses((prev) => ({
      ...prev,
      [questionId]: answer,
    }));
  };

  // Submit feedback
  const handleSubmit = async () => {
    if (!formData) return; 

    const formattedResponses = Object.keys(responses).map((questionId) => ({
      questionId: parseInt(questionId, 10),
      answer: responses[questionId],
    }));

    const submitData = {
      formId: formData.id,
      responses: formattedResponses,
      rating,
    };

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_PORTAL_APP_BASE_URL}/feedback/submitFeedback`, 
        submitData, 
        {
          headers: {
            token: token, 
            "Content-Type": "application/json", 
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        setShowModal(true); 
      } else {
        console.error("Failed to submit feedback:", response.statusText);
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!formData) {
    return <p>Failed to load form data. Please try again later.</p>;
  }

  return (
    <div className="max-w-xl mx-auto mt-10 p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-bold text-center mb-4">{formData.title}</h2>
      {/*Overall Rating */}
          <div className="mt-6">
          <p className="font-medium text-center mb-4">Overall Rating</p>
          <div className="flex justify-center space-x-3">
        {[
          { value: 1, emoji: "😡", label: "Very Bad" },
          { value: 2, emoji: "😟", label: "Poor" },
          { value: 3, emoji: "😐", label: "Medium" },
          { value: 4, emoji: "😊", label: "Good" },
          { value: 5, emoji: "😍", label: "Excellent" },
        ].map(({ value, emoji, label }) => (
          <div key={value} className="flex flex-col items-center">
            <span
              className={`text-4xl cursor-pointer ${
                rating >= value ? "text-yellow-500" : "text-gray-300"
              }`}
              onClick={() => setRating(value)}
            >
              {emoji}
            </span>
            <p
              className={`text-sm mt-2 ${
                rating === value ? "font-bold text-black" : "text-gray-500"
              }`}
            >
              {label}
            </p>
          </div>
        ))}
      </div>
    </div>
       {/* Dynamically render questions */}
        <div className="mt-6">
        {formData.questions.map((question, index) => (
          <div key={index} className="mb-6">
            <p className="font-medium">{question.questionText}</p>
  
            {question.questionType === "star-rating" && (
              <div className="flex justify-center space-x-3">
                {[1, 2, 3, 4, 5].map((value) => (
                  <span
                    key={value}
                    className={`text-4xl cursor-pointer ${
                      responses[index + 1] >= value ? "text-yellow-500" : "text-gray-300"
                    }`}
                    onClick={() => handleResponseChange(index + 1, value)}
                  >
                    ★
                  </span>
                ))}
              </div>
            )}
  
            {question.questionType === "yes-no" && (
              <div className="flex space-x-4">
                {["Yes", "No"].map((option, idx) => (
                  <label key={idx} className="cursor-pointer">
                    <input
                      type="radio"
                      name={`question-${index}`}
                      value={option.toLowerCase()}
                      className="mr-2"
                      onChange={() => handleResponseChange(index + 1, option.toLowerCase())}
                    />
                    {option}
                  </label>
                ))}
              </div>
            )}
  
            {question.questionType === "text" && (
              <textarea
                className="w-full mt-2 p-2 border rounded-md"
                rows="3"
                placeholder="Enter your response..."
                onChange={(e) => handleResponseChange(index + 1, e.target.value)}
              ></textarea>
            )}
          </div>
        ))}
      </div>
  
      {/* Submit Button */}
      <button
        className="w-full mt-6 px-4 py-2 bg-[#B03982] text-white rounded-lg hover:bg-[#9A316E] transition"
        onClick={handleSubmit}
      >
        Submit Feedback
      </button>
        {/* Modal */}
{showModal && (
  <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
    <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full">
      <div className="text-center">
        <div className="mb-6">
          <div className="w-20 h-20 mx-auto bg-blue-500 rounded-full flex items-center justify-center">
            <span className="text-white text-5xl">✔</span>
          </div>
        </div>
        <h2 className="text-3xl font-bold mb-4">Thank You!</h2>
        <p className="text-lg mb-6">
          Your feedback has been successfully submitted. We appreciate your input and will review it promptly.
        </p>
        <button
          className="px-6 py-3 bg-[#B03982] text-white rounded-lg text-lg hover:bg-[#9A316E] transition"
          onClick={() => {
            setShowModal(false);
            navigate("/");
          }}
        >
          Close
        </button>
      </div>
    </div>
  </div>
)}
    </div>
  );
};
export default FeedbackForm;