import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import character from "../assest/cambine.jpg";
import { Globe, ChevronDown, Eye, EyeOff } from "lucide-react";
import backGround from "../assest/authBG.jpg";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, error } = useSelector((state) => state.auth);
  const [localError, setLocalError] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState("English");
  const languages = ["English", "العربية"];

  const handleLogin = () => {
    dispatch(loginUser({ email, password, rememberMe }))
      .unwrap()
      .then(() => {
        navigate("/welcome");
      })
      .catch((err) => {
        if (err.message === "First login. Please change password.") {
          navigate("/change-password", { state: { email } });
        } else {
          console.error("Login failed:", err);
          setLocalError(err.message);
        }
      });
  };

  useEffect(() => {
    const originalStyle = {
      margin: document.body.style.margin,
      padding: document.body.style.padding,
    };
    document.body.style.margin = "0";
    document.body.style.padding = "0";
    return () => {
      document.body.style.margin = originalStyle.margin;
      document.body.style.padding = originalStyle.padding;
    };
  }, []);

  useEffect(() => {
    if (error && error.message !== "First login. Please change password.") {
      setLocalError(
        typeof error === "object" && error.message ? error.message : error
      );
    }
  }, [error]);

  useEffect(() => {
    if (localError) {
      const timer = setTimeout(() => {
        setLocalError(null);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [localError]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div
      className="min-h-screen w-full relative overflow-x-hidden"
      style={{
        backgroundImage: `url(${backGround})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="min-h-screen flex items-center justify-center px-4 py-8">
        <div className="w-full max-w-6xl rounded-3xl shadow-lg flex flex-col md:flex-row bg-white">
          {/* Left Side - Character Image */}
          <div className="hidden md:flex md:w-1/2 items-center justify-center p-8">
            <img
              src={character}
              alt="Login"
              className="w-96 h-auto object-contain"
            />
          </div>

          {/* Right Side - Login Form */}
          <div className="w-full md:w-1/2 flex items-center justify-center p-8">
            <div className="w-96 relative">
              {/* Decorative Shapes in Top Right */}
              <div className="absolute top-[-14.9rem] -right-4">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="396"
                  height="216"
                >
                  <path
                    d="M0 0 C0.83893799 0.00757324 1.67787598 0.01514648 2.54223633 0.02294922 C8.63746703 0.1857422 13.44365219 0.93455965 18.5625 4.4375 C19.2534375 4.88480469 19.944375 5.33210937 20.65625 5.79296875 C28.58406666 11.30990086 34.32704289 17.90244574 36.5625 27.4375 C38.14909737 45.7209114 34.79098259 64.26658224 32.44873047 82.359375 C32.01346878 85.74168002 31.58899673 89.12530009 31.16499424 92.50903225 C30.28844178 99.50277342 29.40527799 106.49571582 28.50219727 113.48608398 C28.26570213 115.32419899 28.26570213 115.32419899 28.02442932 117.19944763 C27.63055908 119.96041647 27.10945249 122.70273755 26.5625 125.4375 C9.0725 125.4375 -8.4175 125.4375 -26.4375 125.4375 C-27.79405961 115.10677679 -29.12874172 104.77426256 -30.4375 94.4375 C-30.56290161 93.44987671 -30.68830322 92.46225342 -30.81750488 91.44470215 C-31.64940481 84.88459885 -32.46903755 78.32309923 -33.27655029 71.75994873 C-33.54813009 69.56427869 -33.82285422 67.36899503 -34.10089111 65.1741333 C-38.92524875 27.01718993 -38.92524875 27.01718993 -29.1875 13.625 C-21.22201924 4.13096216 -12.34097361 -0.15591724 0 0 Z "
                    fill="#993884"
                    transform="translate(232.4375,4.5625)"
                  />
                  <path
                    d="M0 0 C5.77976377 4.39356026 7.74661905 8.81195782 9.00488281 15.71777344 C9.16369556 16.50480526 9.32250832 17.29183708 9.48613358 18.10271835 C9.81615692 19.74470929 10.13868184 21.38822429 10.45387268 23.03312683 C10.89607134 25.32707832 11.36287495 27.61405687 11.84216309 29.9005127 C13.8377264 39.42697031 15.72762952 48.97554682 17.63232422 58.52050781 C18.32534399 61.98944724 19.02589636 65.45673773 19.73291016 68.92285156 C20.59891394 73.16879268 21.45146945 77.41723607 22.29489899 81.66771698 C22.61403023 83.26474491 22.93764858 84.86088411 23.2661972 86.45600128 C28.92422908 113.98538148 28.92422908 113.98538148 21.921875 125.4609375 C16.19732227 133.29576323 9.22020517 139.36149411 -0.078125 142.4609375 C-3.30274128 142.71445111 -6.53207316 142.69225994 -9.765625 142.7109375 C-11.07563477 142.741875 -11.07563477 142.741875 -12.41210938 142.7734375 C-22.69458921 142.83487129 -29.56762306 139.40013966 -37.078125 132.4609375 C-44.19022255 124.8599818 -47.51664343 116.49624825 -47.35906982 106.13336182 C-46.76728442 98.3977251 -45.01979183 90.84658317 -43.4296875 83.265625 C-43.05230287 81.42364247 -42.67596894 79.58144439 -42.30062866 77.73904419 C-41.31547614 72.91740203 -40.31637803 68.09875684 -39.31414795 63.28063965 C-38.3000168 58.39323866 -37.29892925 53.50316994 -36.296875 48.61328125 C-35.16732259 43.10579604 -34.03633763 37.59862648 -32.89663696 32.0932312 C-32.72368292 31.25769274 -32.55072887 30.42215427 -32.3725338 29.56129646 C-32.04069134 27.9606585 -31.70807067 26.36018164 -31.37458229 24.75988579 C-30.37917401 19.97183415 -29.43512044 15.17782234 -28.58554077 10.36135864 C-27.4108053 5.96162917 -24.91580044 2.81311354 -21.2890625 0.14453125 C-14.07820107 -3.97801303 -7.30489013 -3.65244506 0 0 Z "
                    fill="#963984"
                    transform="translate(315.078125,68.5390625)"
                  />
                  <path
                    d="M0 0 C8.67940783 7.13205439 13.94965508 14.5105821 15.12890625 25.9140625 C15.44168549 36.14497044 14.39567419 47.95865162 9.75 57.25 C-11.04 57.25 -31.83 57.25 -53.25 57.25 C-61.86192143 24.52469856 -61.86192143 24.52469856 -54.25 11.25 C-41.38928454 -6.74452448 -18.87233201 -13.25772268 0 0 Z "
                    fill="#783A85"
                    transform="translate(107.25,72.75)"
                  />
                  <path
                    d="M0 0 C6.88956241 5.23718769 8.03144133 10.59848903 9.421875 18.8359375 C9.83059682 21.06797148 10.24079398 23.29973572 10.65234375 25.53125 C10.85778809 26.67239258 11.06323242 27.81353516 11.27490234 28.98925781 C12.50810854 35.6066888 13.96399616 42.17909037 15.3984375 48.75537109 C15.59824219 49.69461426 15.79804688 50.63385742 16.00390625 51.6015625 C16.27037964 52.83181152 16.27037964 52.83181152 16.54223633 54.08691406 C16.921875 56.4609375 16.921875 56.4609375 16.921875 61.4609375 C-1.228125 61.4609375 -19.378125 61.4609375 -38.078125 61.4609375 C-36.54764425 51.51281259 -34.97088254 41.59189395 -33.21118164 31.68432617 C-32.91277664 29.98595206 -32.62116702 28.28637073 -32.33618164 26.58569336 C-31.91649279 24.08647378 -31.47423512 21.59213744 -31.02734375 19.09765625 C-30.84578987 17.97395882 -30.84578987 17.97395882 -30.66056824 16.82756042 C-29.37378072 9.89494709 -26.55860759 4.39571392 -20.953125 0.0234375 C-13.84130429 -3.84168245 -7.14507588 -3.57253794 0 0 Z "
                    fill="#823A85"
                    transform="translate(168.078125,68.5390625)"
                  />
                  <path
                    d="M0 0 C4.75771411 2.7268662 7.72199298 6.52384206 9.8125 11.5625 C10.66345075 16.64007388 10.31867228 20.98443349 8.75 25.875 C4.94876035 31.14768726 0.36673613 35.3827375 -6.1875 36.5625 C-12.83341652 36.76088557 -18.22329706 35.05605616 -23.1875 30.5625 C-27.15341391 25.63582948 -27.82301643 20.37389179 -27.57421875 14.1796875 C-26.81008359 9.00826775 -24.55092857 5.85600915 -20.75 2.25 C-14.22603006 -2.04627289 -7.33536486 -3.07748066 0 0 Z "
                    fill="#733B85"
                    transform="translate(32.1875,67.4375)"
                  />
                  <path
                    d="M0 0 C4.30221201 3.68761029 8.0684879 7.80548303 8.99609375 13.546875 C9.17330104 20.09312068 7.88516814 24.23735651 3.625 29.25 C-2.19364603 34.52737664 -6.21380108 34.59449345 -13.9453125 34.53125 C-19.52427824 34.07374939 -21.92231606 31.78338861 -25.625 27.875 C-29.24986698 22.43769952 -29.73529155 18.00818808 -28.9140625 11.6484375 C-27.64517481 6.00280676 -24.06492946 2.37661964 -19.375 -0.75 C-12.99522859 -3.86570232 -6.19016625 -3.20971583 0 0 Z "
                    fill="#833A85"
                    transform="translate(382.375,176.75)"
                  />
                </svg>
              </div>

              <div className="space-y-6">
                <input
                  id="email"
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full h-12 px-4 border-2 border-[#B03982] rounded-lg focus:outline-none focus:ring-0 focus:ring-[#B03982]"
                />

                <div className="relative">
                  <input
                    id="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter your password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full h-12 px-4 border-2 border-[#B03982] rounded-lg focus:outline-none focus:ring-0 focus:ring-[#B03982]"
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="absolute right-4 top-1/2 transform -translate-y-1/2"
                  >
                    {showPassword ? (
                      <EyeOff size={20} className="text-[#B03982]" />
                    ) : (
                      <Eye size={20} className="text-[#B03982]" />
                    )}
                  </button>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      id="rememberMe"
                      checked={rememberMe}
                      onChange={() => setRememberMe(!rememberMe)}
                      className="mr-2 bg-[#B03982] text-[#B03982] rounded-full"
                    />
                    <label
                      htmlFor="rememberMe"
                      className="text-sm text-[#B03982]"
                    >
                      Remember Me
                    </label>
                  </div>
                  <a
                    href="/forgot-password"
                    className="text-sm text-[#B03982] hover:text-purple-700"
                  >
                    Forgot Password?
                  </a>
                </div>

                <div className="flex justify-end">
                  <button
                    onClick={handleLogin}
                    className="w-[50%] h-10 bg-gradient-to-b from-[#B03982] to-[#733C86] text-white font-semibold rounded-full hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500"
                    disabled={isLoading}
                  >
                    {isLoading ? "Logging in..." : "Login"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Language selector */}
      <div className="absolute bottom-[6rem] left-[12.5rem]">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="flex items-center gap-2 px-3 py-2"
        >
          <Globe size={20} />
          <span>{selected}</span>
          <ChevronDown
            size={16}
            className={`transition-transform ${isOpen ? "rotate-180" : ""}`}
          />
        </button>

        {isOpen && (
          <div className="absolute mt-1 w-48 bg-white border rounded-md shadow-lg">
            {languages.map((lang) => (
              <button
                key={lang}
                onClick={() => {
                  setSelected(lang);
                  setIsOpen(false);
                }}
                className="w-full px-4 py-2 text-left hover:bg-gray-100 first:rounded-t-md last:rounded-b-md"
              >
                {lang}
              </button>
            ))}
          </div>
        )}
      </div>

      {/* Error Notification */}
      {localError && (
        <div className="fixed top-4 left-1/2 transform -translate-x-1/2 w-96 p-4 bg-red-600 text-white rounded-lg shadow-md flex items-center justify-between">
          <span>
            {typeof localError === "string"
              ? localError
              : JSON.stringify(localError)}
          </span>
          <button
            className="ml-4 bg-red-800 rounded-full w-10 p-1 text-lg"
            onClick={() => setLocalError(null)}
          >
            ×
          </button>
        </div>
      )}
    </div>
  );
};

export default Login;
